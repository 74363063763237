:root {
  --regular: "museo-sans";
  --light: SFUIDisplayLight, sans-serif;
  --medium: SFUIDisplayMedium, sans-serif;
  --bold: SFUIDisplayBold, sans-serif;
  --semibold: SFUIDisplaySemibold, sans-serif;
  --parent-top-gap: 0px;
  --maxParentheight: calc(100vh - var(--parent-top-gap));
}

// --black: SFUIDisplayBlack,sans-serif;
// --thin: SFUIDisplayThin,sans-serif;
// --heavy:SFUIDisplayHeavy,sans-serif;
// --regularNova: ProximaNova-Regular,sans-serif;
// --lightNova: ProximaNova-Light,sans-serif;
// --SemiboldNova: ProximaNova-Semibold,sans-serif;
// --ultralight:SFUIDisplayUltralight,sans-serif;

// Utility fonts sizes
$font0: 0rem;
$font8: 0.5rem;
$font9: 0.5625rem;
$font10: 0.625rem;
$font11: 0.6875rem;
$font12: 0.75rem;
$font13: 0.8125rem;
$font14: 0.875rem;
$font15: 0.9375rem;
$font16: 1rem;
$font17: 1.0625rem;
$font18: 1.125rem;
$font19: 1.1875rem;
$font20: 1.25rem;
$font21: 1.3125rem;
$font22: 1.375rem;
$font23: 1.4375rem;
$font24: 1.5rem;
$font25: 1.5625rem;
$font26: 1.625rem;
$font27: 1.6875rem;
$font28: 1.75rem;
$font29: 1.8125rem;
$font30: 1.8125rem;
$font31: 1.8125rem;
$font32: 2rem;
$font33: 2.0625rem;
$font34: 2.125rem;
$font35: 2.1875rem;
$font36: 2.25rem;
$font37: 2.3125rem;
$font38: 2.375rem;
$font39: 2.4375rem;
$font40: 2.5rem;
$font41: 2.5625rem;
$font42: 2.625rem;
$font43: 2.6875rem;
$font44: 2.75rem;
$font45: 2.8125rem;

// font sizes
$fontPrimary: 0rem;
$fontProfileName: 0.795rem;
$fontProfilePopupLabel: 1.063em;
$fontProfileDetailsLabel: 0.675rem;
$fontProfileDetailsInfo: 0.65rem;
$fontImageGalleryIndex: 0.875rem;
$fontQrLink: 0.875rem;
$fontStartChat: 0.6875rem;
$StartChartH3: 1.875rem;
$StartChartP: 1.125rem;
$fontNorecentChatH4: 1.125rem;
$fontNetworkoffline: 1rem;
$fontchatStatusBar: 0.57rem;
$fontPopupLabel: 0.9375rem;
$fontPopupNoteinfoButton: 0.75rem;
$font-profile-label: 0.675rem;
$font-profile-info-span: 0.65rem;
$font-popup-username-count: 0.75rem;

// primary color codes
$primary: #4c2766;
$primary-dark: #08060a;
$white: #fff;
$textblack: #313131;
$textDark: #181818;
$lightGray: #959595;
$darkGray: #474747;
$mediahead: #4c2766;
$rechathead: #181818;
$popupHeadingLabel: #181818;
$rechatmsg: #767676;
$rechathead: $textDark;
$delete: #e24848;
$darkblack: #000;
$popuptext: #818181;
$button: #e5e5e5;
$lightdark: #333333;
$mediainnerbg: #e3e3e3;
$mediainnerborder: #bebebe;
$mediacontactinfoborder: #e7e7e7;
$msgtime: #4b5667;
$rechatmsgactive: $darkGray;
$mediainnertext: $lightdark;
$profiledropdownbg: rgba(76, 39, 102, 0.101961);
$lightblack: #333;
$popupoverlay: rgba(0, 0, 0, 0.8);
$overlay: rgba(0, 0, 0, 0.5);
$profileimgfill: $primary;
$profiletextcolor: $textDark;
$darkgraytext: #878787;
$placeholdertextcolor: #b3b3b3;
$attachments-header: $primary;
$bgc-scrollbar-track: #e8e8e8;
$bgc-scrollbar-track-hover: $bgc-scrollbar-track;
$bdr-scrollbar-track: $white;
$bgc-scrollbar-thumb: #b7b7b7;
$bgc-scrollbar-thumb-hover: #b7b7b7;
$bdr-scrollbar-thumb-hover: #b7b7b7;
$bgc-chatMessage-status-sent: #66e69a;
$bgc-chatMessage-status-notsent: #ffc400;
$bgc-chatMessage-status-offline: #dfdfdf;
$bgc-chatMessage-date: #97b2ce;
$bgc-tomoto: #fba843;
$bgc-light-blue: #f3f7ff;
// secondary color codes
$clr-send-message-text: #444343;
$clr-send-message-time: #949494;
$clr-rev-message-time: #fff;
$fill-send-message-star: #545454;
$clr-send-message-text-link: $primary;
$clr-rev-message-text-link: #ffffff;
$bgc-message-image: $white;
$bgc-message-image-overlay: rgba(0, 0, 0, 0);
$fill-message-image-overlay-svg: $white;
$clr-send-attach-file-details: $textDark;
$bgc-send-attach-file-details: $textDark;
$clr-rev-attach-file-details: $white;
$bgc-rev-attach-file-details: $white;
$bgc-send-contact-message: $white;
$fill-send-contact-message: $textblack;
$clr-send-contact-message: $textblack;
$bgc-send-contact-option: $white;
$bgc-rev-contact-option: $primary;
$bdr-contact-option: #d8d6d6;
$bdr-rev-contact-option: rgba(255, 255, 255, 0.3);
$bgc-contact-option-hover: $white;
$clr-contact-option-hover: $textDark;
$clr-send-document-message: $textblack;
$bgc-send-document-message-block: #efefef;
$fill-send-document-message-download-svg: #747474;
$clr-rev-document-message: $white;
$bgc-rev-document-message-block: transparent;
$fill-rev-document-message-download-svg: $white;
$bgQrLoadContainer: rgba(255, 255, 255, 0.71);
$colorlogoBlockLink: #3ec3fa;
$bgc-userprofile-popup: $white;
$bdr-userprofile-header: #cfcfcf;
$bgc-userprofile-header: $primary;
$bgc-profile-dropdown: $white;
$bgc-profile-dropdown-li-hover: rgba(76, 39, 102, 0.101961);
$clr-profile-dropdown-li-span: #333;
$bgc-removephoto-container: rgba(255, 255, 255, 1);
$bgc-camera-popup-not-found: $white;
$bgc-camera-popup-visible: $white;
$bgc-removephoto-popup: $white;
$bgc-emoji-picker-react: $white;
$clr-profile-label: #95aac5;

$clr-profile-info-span: rgba(138, 141, 145, 0.65);
$video-controls: #1b64d8;
$particpant-count: #12233e;

// recent
$bgc-recent-chatlist: $white;
$bgc-recent-chatlist-loader-container: $white;
$bgc-recent-chatlist-header-profile-options-svg-path: #828282;
$bgc-recent-chatlist-menu-dropdown: $white;
$bgc-recent-chatlist-menu-dropdown-li-hover: rgba(76, 39, 102, 0.101961);
$clr-recent-chatlist-menu-dropdown-li-span: #333;
$bgc-search-input: #f1f3f4;
$bgc-activeCall: #1bb261;
$bgc-activeIncommingCall: #daffd1;
$bgc-activeIncommingCall-hover: #c9f5be;
$clr-recevingCall: $darkGray;
$clr-recent-message-block-span: #4a5568;
$bgc-user-status: rgb(0, 226, 75);
$bgc-user-status-sent: gray;
$bgc-user-status-receive: orange;
$bgc-chat-list-li-active: rgba(76, 39, 102, 0.101961);

// No recent chat
$crl-norecent-chat-h6: #333333;
$crl-norecent-chat-h4: #333333;
$crl-errorMesage: #f44336;
$font-errorMesage: 0.75rem;
$bgc-recent-chatlist-header: $primary;
$bgc-newGroup: $white;
$bgc-selectedBadge: #c5c5c5;
$bgc-selectedBadge-Title: $textDark;
$bgc-selectedBadge-hover: #8a8585;
$bgc-onGoingcallDuration: #1bb261;
$clr-onGoingcallDuration: $white;
$clr-onGoingcallDuration-callerName: #50ffad;
$bgg-message-dropdown-i:
  21deg,
  rgba(0, 0, 0, 0),
  rgba(0, 0, 0, 0) 45%,
  rgba(0, 0, 0, 0.2) 60%,
  rgba(0, 0, 0, 0.4);
$fill-message-dropdown-svg: #6a92c5;
$fill-message-dropdown-menu: $white;

// conversation style start
$clr-conversation-header-profile-name-h4: $textDark;
$clr-conversation-header-profile-name-h6: #4a5568;
$fill-profile-options-svg-path: #828282;

// chatconversation-container
$fill-chatconversation-profile-option-svg-path: #828282;
$bgc-closedropmedia: #f3f3f3;
$fill-choose-upload-files-svg: $white;
$clr-drop-text: #d4d4d4;
$clr-drop-file-types: #4b4b4b;
$bgc-drop-file-types-span: #d4d4d4;
$bgc-selected-files-preview: $white;
$bgc-selected-files-preview: rgba(0, 0, 0, 0.6);
$bgc-selected-files-Close-icon: #414141;
$bgc-selected-files-name: $white;
$bgc-selected-add-files-span: #dadcde;
$bgc-selected-files-preview-closedropmedia: #b6b6b6;
$fill-selected-files-preview-closedropmedia-svg: $white;
$clr-typing-area: #363636;
$bgc-typing-area: $white;
$clr-typing-area-focus: #363636;
$bgc-attachment: rgba(226, 233, 240, 0.7);
$fill-attachment-svg: #363636;
$bgc-message-areaformbtns: $white;

// recordAudio style start
$bgc-liveRecord: #d93134;
$clr-chatDate: #3c4452;
$fill-recallstatus-path: $textblack;
$bgc-SingleForward: #c8cacb;
$fill-recallstatus: #fefefe;
$bgc-Viewphoto-container: $white;
$bgc-group-details-container-li: rgba(76, 39, 102, 0.101961);
$clr-group-details-container-li-text: #333;
$clr-profile-status-admin-text: $darkblack;
$bgc-contactinfo-dropdown: $white;
$bgc-contactinfo-dropdown-text: $lightblack;
$bgc-contactinfo-media-profile-image: $primary;
$bgc-userprofile-overlay: $overlay;
$bgc-toggleAnimation: #f1f3f4;
$bgc-imagePreviewContainer: rgba(0, 0, 0, 0.95);
$clr-image-gallery-icon-hover: $white;
$fill-imagePreviewContainer-close-svg: #f1ebeb;
$fill-imagePreviewContainer-options-svg: rgba(139, 68, 68, 0);
$clr-image-gallery-nav: $darkblack;
$bgc-image-gallery-nav: $white;
$bgc-image-gallery-nav: $white;
$bdr-image-gallery-thumbnail-focus: $white;
$bgc-image-gallery-play-button: rgba(0, 0, 0, 0.7);
$bgc-image-gallery-slide-before: #0d0d0d6b;
$clr-emoji-mart-category-label: #858585;
$clr-emoji-mart-anchor-hover: #464646;
$clr-memberTyping: #3464e3;
$clr-memberTyping-status: #3464e3;
$clr-typing-area-empty-before: #b3b3b3;
$bgc-callingAnimation-dot: $white;
$bgc-networkoffline: #f2a12b;
$clr-networkoffline: $textDark;

// mirrorfly login
$bxs-login-content: #7878781a;
$bgc-login-content: $white;
$clr-login-wrapper-h2: #333333;
$clr-login-wrapper-guidelines: #333333;
$clr-login-wrapper-link: #21a5f1;

// start chat banner
$clr-start-chat-banner-link: $white;
$bgc-start-chat-banner-link: #4c2766;
$clr-start-chat-banner-heading: #333333;
$clr-start-chat-banner-sub-heading: #333333;
$bgc-chatStatusBar: rgba(76, 39, 102, 0.2);
$bgg-image-message-before: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
$bgg-image-message-after: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
$bgg-image-message-before-hover: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
$bgg-image-message-after-hover: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
$bgc-popup-container-header: $primary;
$clr-popup-container-header: $white;
$fill-popup-container-header-svg: $white;

// add-participant, .contactPopup
$bgc-popup-search-input: rgba(76, 39, 102, 0.1);
$clr-popup-search-input: $rechathead;
$bdr-popup-search-input: 1px solid rgba(76, 39, 102, 0.5);
$bgc-popup-footer: #e7e7e7;
$bgc-popup-footer-button: $primary;
$bgc-popup-footer-button-svg: $white;
$clr-popup-badge: #383636;
$clr-contactPopup-name: #000000;
$clr-contactPopup-no: #000000;

// .newgroup-details-container
$bgc-newgroup: #f6f6f6;
$bgc-newgroup-image: $white;
$clr-newgroup-image-before: $white;
$bgc-newgroup-image-before: rgba(0, 0, 0, 0.236);
$fill-userprofile-header-svg: $white;
$clr-userprofile-header-h5: $white;

// .Viewphoto-container,.camera-container,.removephoto-container {
$bgc-newgroup-photo-container: $white;

// camera popup not found styles
$bgc-popup: $white;
$bxs-popup: #00000024;
$clr-popup-header-h4: $primary;
$bgc-popup-button-primary: $primary;
$bgc-popup-button-default: $button;
$clr-popup-button: $white;
$clr-popup-header-label: $rechatmsg;
$bdr-popup-profile-username-input: 1px solid #ccc;

// .reply-block-bottom
$bgc-reply-block-bottom: $white;
$bgc-reply-container: #efefef;
$fill-reply-bottom-sender-sends-i: #767676;
$fill-reply-webchat-conver-image-svg: $textDark;

// loader
$bgc-popup-body-loader: $white;

// forwardSelect
$bgc-forwardSelect: rgba(72, 121, 249, 0.15);
$bdr-forward-checkbox: #adadad;
$bgc-forward-checkbox-checked: $primary;

// selectedMessagecontainer
$bgc-selectedMessage: $white;
$bgc-selectedMessageOption: $white;
$bgc-selectedMessage-close: #383636;
$clr-rev-sender-name: $white;
$fill-rev-sender-sends: $white;
$fill-sen-webchat-conver-image-svg: $lightGray;
$clr-sen-sender-sends: $textblack;
$clr-sen-sender-sends-send: $textblack;
$fill-sen-sender-sends-svg: $textDark;
$bgc-animatefinded: #4879f95e;
$conversation-overlay: rgba(0, 0, 0, 0.2);
$bxs-infoContainer: #00000014;
$clr-infoContainer-label: #383636;
$bxs-infoContainer-by-read-delivery: #0000001a;
$clr-infoContainer-read-delivery-heading: #383636;
$clr-infoContainer-read-delivery-status: #383636;
$bgc-blockedContainer: $primary;
$bgc-blockedContainer-span: $white;
$bgc-send-reply-block-tagged: $white;
$bgc-send-reply-block-tagged-attach: $primary;
$bgc-rev-reply-block-container: #4c2766;
$bgc-reply-block-container: #efefef;
$fill-rep-message-sender-sends-svg: $white;
$clr-rev-rep-reply-message-type: #fff;
$clr-send-rep-reply-message-type: $textDark;
$bgc-send-rep-reply-message-type: #d8d8d8;
$bgc-rep-reply-message-type-icon: $lightGray;
$fill-rep-reply-message-type-icon: #efefef;
$bgc-rep-reply-message-type-videoOverlay: rgba(0, 0, 0, 0.49);

// contact popup
$clr-contact-detail-popup-text: $white;
$bgc-chat-list-li-active: rgba(76, 39, 102, 0.101961);
$bgc-chat-list-li-hover: rgba(76, 39, 102, 0.101961);
$bgc-chat-list-li-recent-msg: rgba(76, 39, 102, 0.101961);

// .mediaAttachCamera
$bgc-mediaAttachCamera: #dee1e8;
$bgc-mediaAttachCamera-popup: $white;
$bgc-mediaAttachCamera-popup-header: $primary;
$fill-mediaAttachCamera-popup-header-svg: $white;
$clr-mediaAttachCamera-popup-header: $white;
$clr-mediaAttachCamera-button: $primary;
$clr-mediaAttachCamera-caption-input: $white;

// StyleGuide
$space10: 10px; // Header Spacing
$space15: 15px;
$space30: 30px;
$space25: 25px;
$space50: 50px;
$space75: 75px;

// Baron variables
$sp_primary: #1c135f;
$sp_primary_light: #eeedff;
$sp_secondary: #6f1748;
$sp_secondary_light: #eeedff;
$sp_tricary: #f79320;
$sp_quaternary: #2b2870;
$sp_quaternary_light: #c9c8da;
$color_light: #fff;
$color_dark: #000;
$body_font_color_old: #58595b;
$body_font_color: #000;
$sp_border_color: #d7d5d5;
$change_bg: #dad9e6;
$radio_border_color: #757575;
$radio_shadow_color: #f4f4f4;
$toast_success: #4abb41;
$table_emailcolor: #c80035;
$best_value: #dfb341;
$performance_bg: #f8f8f8;
$text_count: #707070;
$inputbox_bg: rgba(245, 245, 245, 0.65);
// Font-family
$sp_font_slab: "SF-Pro-Text-Bold";
$sp_font_sans: "SFPRODISPLAYREGULAR";

// Fonts family
$regular: SFUIDisplayRegular, sans-serif;
$light: SFUIDisplayLight, sans-serif;
$medium: SFUIDisplayMedium, sans-serif;
$bold: SFUIDisplayBold, sans-serif;
$semibold: SFUIDisplaySemibold, sans-serif;
// $ultralight:SFUIDisplayUltralight,sans-serif;
// $regularNova: ProximaNova-Regular,sans-serif;
// $SemiboldNova: ProximaNova-Semibold,sans-serif;
// $thin: SFUIDisplayThin,sans-serif;
// $lightNova:ProximaNova-Light,sans-serif;
