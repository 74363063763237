@font-face {
  font-family: "SFUIDisplayBold";
  src:
    url("../fonts/new/newAnother/SFUIDisplay-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/new/newAnother/SFUIDisplay-Bold.otf") format("opentype"),
    url("../fonts/new/newAnother/SFUIDisplay-Bold.woff") format("woff"),
    url("../fonts/new/newAnother/SFUIDisplay-Bold.ttf") format("truetype"),
    url("../fonts/new/newAnother/SFUIDisplay-Bold.svg#SFUIDisplay-Bold") format("svg");
}

@font-face {
  font-family: "SFUIDisplayMedium";
  src:
    url("../fonts/new/newAnother/SFUIDisplay-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/new/newAnother/SFUIDisplay-Medium.otf") format("opentype"),
    url("../fonts/new/newAnother/SFUIDisplay-Medium.woff") format("woff"),
    url("../fonts/new/newAnother/SFUIDisplay-Medium.ttf") format("truetype"),
    url("../fonts/new/newAnother/SFUIDisplay-Medium.svg#SFUIDisplay-Medium") format("svg");
}

@font-face {
  font-family: "SFUIDisplaySemibold";
  src:
    url("../fonts/new/newAnother/SFUIDisplay-Semibold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/new/newAnother/SFUIDisplay-Semibold.otf") format("opentype"),
    url("../fonts/new/newAnother/SFUIDisplay-Semibold.woff") format("woff"),
    url("../fonts/new/newAnother/SFUIDisplay-Semibold.ttf") format("truetype"),
    url("../fonts/new/newAnother/SFUIDisplay-Semibold.svg#SFUIDisplay-Semibold") format("svg");
}

@font-face {
  font-family: "SFUIDisplayRegular";
  src:
    url("../fonts/new/SFPRODISPLAYREGULAR.eot") format("embedded-opentype"),
    url("../fonts/new/SFPRODISPLAYREGULAR.otf") format("opentype"),
    url("../fonts/new/SFPRODISPLAYREGULAR.woff") format("woff");
}

@font-face {
  font-family: "SFUIDisplayLight";
  src: url("../fonts/new/newAnother/SFUIDisplayLight.eot");
  src:
    url("../fonts/new/newAnother/SFUIDisplayLight.eot") format("embedded-opentype"),
    url("../fonts/new/newAnother/SFUIDisplayLight.woff2") format("woff2"),
    url("../fonts/new/newAnother/SFUIDisplayLight.woff") format("woff"),
    url("../fonts/new/newAnother/SFUIDisplayLight.ttf") format("truetype"),
    url("../fonts/new/newAnother/SFUIDisplayLight.svg#SFUIDisplayLight") format("svg");
}

@font-face {
  font-family: "SF-Pro-Text-Bold";
  src:
    url("../fonts/new/SF-Pro-Text-Bold.woff") format("woff"),
    url("../fonts/new/SF-Pro-Text-Bold.woff2") format("woff2"),
    url("../fonts/new/SF-Pro-Text-Bold.svg#SF-Pro-Text-Bold") format("svg"),
    url("../fonts/new/SF-Pro-Text-Bold.eot"),
    url("../fonts/new/SF-Pro-Text-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/new/SF-Pro-Text-Bold.otf") format("truetype");
}

@font-face {
  font-family: "SFPRODISPLAYREGULAR";
  src:
    url("../fonts/new/SFPRODISPLAYREGULAR.woff") format("woff"),
    url("../fonts/new/SFPRODISPLAYREGULAR.woff2") format("woff2"),
    url("../fonts/new/SFPRODISPLAYREGULAR.eot"),
    url("../fonts/new/SFPRODISPLAYREGULAR.eot?#iefix") format("embedded-opentype"),
    url("../fonts/new/SFPRODISPLAYREGULAR.otf") format("truetype");
}

@font-face {
  font-family: "museo-sans";
  src:
    url("https://use.typekit.net/af/620bf8/00000000000000000000e7fe/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/620bf8/00000000000000000000e7fe/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("woff"),
    url("https://use.typekit.net/af/620bf8/00000000000000000000e7fe/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: "museo-sans";
  src:
    url("https://use.typekit.net/af/5cca6d/00000000000000000000e802/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/5cca6d/00000000000000000000e802/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3")
      format("woff"),
    url("https://use.typekit.net/af/5cca6d/00000000000000000000e802/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: "museo-sans";
  src:
    url("https://use.typekit.net/af/a28b50/00000000000000000000e803/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/a28b50/00000000000000000000e803/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("woff"),
    url("https://use.typekit.net/af/a28b50/00000000000000000000e803/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: "museo-sans";
  src:
    url("https://use.typekit.net/af/c2d3de/00000000000000000000e804/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/c2d3de/00000000000000000000e804/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3")
      format("woff"),
    url("https://use.typekit.net/af/c2d3de/00000000000000000000e804/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: "museo-sans";
  src:
    url("https://use.typekit.net/af/e3ca36/00000000000000000000e805/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/e3ca36/00000000000000000000e805/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/e3ca36/00000000000000000000e805/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "museo-sans";
  src:
    url("https://use.typekit.net/af/2841b6/00000000000000000000e806/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/2841b6/00000000000000000000e806/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/2841b6/00000000000000000000e806/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
}
